@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$mapdone-primary: #6ea8fe;
$mapdone-info: #484;
$mapdone-danger: #E85F5C;

$primary: $mapdone-primary;
$info: $mapdone-info;
$danger: $mapdone-danger;
$mapdone-theme-colors: (
        primary: $mapdone-primary,
        info: $mapdone-info,
        danger: $mapdone-danger,
);
$theme-colors: map-merge($theme-colors, $mapdone-theme-colors);

$form-check-input-checked-bg-color: $mapdone-primary;
$dropdown-link-active-bg: $mapdone-primary;

$border-radius:               .15rem;
$border-radius-sm:            .15rem;
$border-radius-lg:            .15rem;
$border-radius-xl:            .15rem;
$border-radius-xxl:           .15rem;
$border-radius-pill:          .15rem;

$spacer: .5rem;

$font-family-base: "Raleway";
$font-family-code: "Fira Code";
$font-family-monospace: "Fira Code";
$font-size-base: 1rem;
$line-height-base: 1.5;

.navbar-brand {
  font-family: "Sirin Stencil", sans-serif;
  font-size: 2em !important;
}

@import "bootstrap/scss/bootstrap";
@import "highlighting/prism";

html {
  overflow-y: scroll;
}

.done .child-title {
  text-decoration: line-through;
}

.has-children {
  font-weight: 600;
}

#focus-item {
  margin-bottom: 1em;
}

.btn-toolbar {
  display: flex;
  flex-wrap: nowrap;
}

.btn-group + .btn-group {
  margin-left: 0.5em;
}

$todo-checkbox-width: 42px;

.child {
  &:not(:first-child) {
    padding-top: 0.75em;
  }

  .input-group-text {
    //background-color: $body-bg;
  }

  a {
    text-decoration: none;
  }

  .child-title a:hover {
    color: $secondary;
  }

  .note {
    width: $todo-checkbox-width;
  }
}

.parent-badge {
  margin-left: $todo-checkbox-width;
}

.children {
  margin-bottom: 2.5em;
}

.ws-status {
  margin-left: 0.5em;
  margin-right: 0.5em;

  &.not-yet-connected {
    color: $secondary;
  }

  &.connected {
    color: $info;
  }

  &.disconnected-retrying {
    color: $warning;
  }

  &.desync {
    color: $danger;
  }
}

form .row + .row {
  margin-top: 0.5em;
}

// rehype-prism-plus-instructions https://github.com/timlrx/rehype-prism-plus
.markdown {
  pre {
    overflow-x: auto;
  }

  /**
   * Inspired by gatsby remark prism - https://www.gatsbyjs.com/plugins/gatsby-remark-prismjs/
   * 1. Make the element just wide enough to fit its content.
   * 2. Always fill the visible space in .code-highlight.
   */
  .code-highlight {
    float: left; /* 1 */
    min-width: 100%; /* 2 */
  }

  .code-line {
    display: block;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -16px;
    margin-right: -16px;
    border-left: 4px solid rgba(0, 0, 0, 0); /* Set placeholder for highlight accent border color to transparent */
  }

  .code-line.inserted {
    background-color: rgba(16, 185, 129, 0.2); /* Set inserted line (+) color */
  }

  .code-line.deleted {
    background-color: rgba(239, 68, 68, 0.2); /* Set deleted line (-) color */
  }

  .highlight-line {
    margin-left: -16px;
    margin-right: -16px;
    background-color: rgba(55, 65, 81, 0.5); /* Set highlight bg color */
    border-left: 4px solid rgb(59, 130, 246); /* Set highlight accent border color */
  }

  .line-number::before {
    display: inline-block;
    width: 1rem;
    text-align: right;
    margin-right: 16px;
    margin-left: -8px;
    color: rgb(156, 163, 175); /* Line number color */
    content: attr(line);
  }
}

// theme: synth wave '84
.markdown {
  /*
   * Synthwave '84 Theme originally by Robb Owen [@Robb0wen] for Visual Studio Code
   * Demo: https://marc.dev/demo/prism-synthwave84
   *
   * Ported for PrismJS by Marc Backes [@themarcba]
   */

  code[class*="language-"],
  pre[class*="language-"] {
    color: #f92aad;
    text-shadow: 0 0 2px #100c0f, 0 0 5px #dc078e33, 0 0 10px #fff3;
    background: none;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  /* Code blocks */
  pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
  }

  :not(pre) > code[class*="language-"],
  pre[class*="language-"] {
    background-color: transparent !important;
    background-image: linear-gradient(to bottom, #2a2139 75%, #34294f);
  }

  /* Inline code */
  :not(pre) > code[class*="language-"] {
    padding: .1em;
    border-radius: .3em;
    white-space: normal;
  }

  .token.comment,
  .token.block-comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #8e8e8e;
  }

  .token.punctuation {
    color: #ccc;
  }

  .token.tag,
  .token.attr-name,
  .token.namespace,
  .token.number,
  .token.unit,
  .token.hexcode,
  .token.deleted {
    color: #e2777a;
  }

  .token.property,
  .token.selector {
    color: #72f1b8;
    text-shadow: 0 0 2px #100c0f, 0 0 10px #257c5575, 0 0 35px #21272475;
  }

  .token.function-name {
    color: #6196cc;
  }

  .token.boolean,
  .token.selector .token.id,
  .token.function {
    color: #fdfdfd;
    text-shadow: 0 0 2px #001716, 0 0 3px #03edf975, 0 0 5px #03edf975, 0 0 8px #03edf975;
  }

  .token.class-name {
    color: #fff5f6;
    text-shadow: 0 0 2px #000, 0 0 10px #fc1f2c75, 0 0 5px #fc1f2c75, 0 0 25px #fc1f2c75;
  }

  .token.constant,
  .token.symbol {
    color: #f92aad;
    text-shadow: 0 0 2px #100c0f, 0 0 5px #dc078e33, 0 0 10px #fff3;
  }

  .token.important,
  .token.atrule,
  .token.keyword,
  .token.selector .token.class,
  .token.builtin {
    color: #f4eee4;
    text-shadow: 0 0 2px #393a33, 0 0 8px #f39f0575, 0 0 2px #f39f0575;
  }

  .token.string,
  .token.char,
  .token.attr-value,
  .token.regex,
  .token.variable {
    color: #f87c32;
  }

  .token.operator,
  .token.entity,
  .token.url {
    color: #67cdcc;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }

  .token.inserted {
    color: green;
  }
}

// mapdone-specific tweaks
.markdown {
  thead tr {
    border-bottom: 1px $secondary solid;
  }

  th:not(:first-of-type) {
    padding-left: 1em;
  }

  td:not(:first-of-type) {
    padding-left: 1em;
  }

  blockquote {
    font-size: 1.2em;
    margin: 0.1em 0 0.6em 0.1em;
    font-family: "Times New Roman", serif;
    font-style: italic;
    color: $light;
    padding: 0.1em 0.5em 0.2em 0.5em;
    border-left: 2px solid $secondary;
    line-height: 1.6;
    background: $dark;

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}

.children-status {
  padding-bottom: 2em;
  color: $secondary;
}

.detail-status {
  padding-bottom: 2em;
  color: $secondary;
}

.notification-level-error {
  .toast-header {
    background-color: #ff6666;
    color: #770000;
  }
}

thead tr {
  border-bottom: 1px $secondary solid;
}

th:not(:first-of-type) {
  padding-left: 1em;
}

td:not(:first-of-type) {
  padding-left: 1em;
}

td {
  vertical-align: middle;
}

.breadcrumb a {
  text-decoration: none;
  &.done {
    text-decoration: line-through;
  }
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

#keyboard-selected {
  border: dashed 5px deeppink;
}

.link-list .list-group-item {
  border: none;
}

.child-title hr {
  opacity: 1;
  color: $light;
}

h3.title {
  text-wrap: initial;
}

.child-title {
  text-wrap: initial;
  text-align: left;
}

.toast-body a {
  text-decoration: underline !important;
  font-weight: bold;
  color: $primary !important;
}

.toast-body a:hover {
  cursor: pointer;
}